import { ChangeEvent, ReactNode, useContext, useEffect, useState } from 'react';
import appContext from '../../context/app.context';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import nlpService from '../../services/nlp.service';
import axios from 'axios';
import './gallery-panel.css';
import { getFavoriteGallery, nlpStore, updateFavoriteGallery, updateMessage } from '../../store/npl.slice';
import { useSelector } from 'react-redux';
import { take } from 'rxjs';

export default function GalleryPanel() {
    const { t, i18n } = useTranslation();
    const { galleryLightbox }: any = useContext(appContext);
    const favoriteGallerySelector = useSelector(getFavoriteGallery);

    useEffect(() => {
        const fetchGallery = async () => {
            nlpService.getFavoriteGalery()
                .then(favArray => {
                    nlpStore.dispatch(updateFavoriteGallery({ favArray }))
                });
        }
        fetchGallery();

        return () => {

        }
    }, [galleryLightbox]);

    const downloadImage = async (url: string) => {
        const response = await nlpService.downloadImage(url);
        const type: string = response.headers['content-type'];
        const imageBlob = new Blob([response.data], { type: `image/${type}` });
        const imageUrl = URL.createObjectURL(imageBlob);

        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(imageUrl);
    };

    const deleteFavImage = async (item: any) => {
        const response = await nlpService.deleteFavImage({ ...item, isFavorite: false });
        if (response.status === 200) {
            nlpStore.dispatch(updateMessage(response.data));
            const galleryImages = favoriteGallerySelector.filter(item => item._id !== response.data._id);
            nlpStore.dispatch(updateFavoriteGallery({ favArray: galleryImages }));
        }
    };

    return (
        <div id="lightbox" onClick={() => { galleryLightbox.toggle(false) }}>
            <div className="gallery-panel-container" onClick={(e: any) => { e.stopPropagation() }}>
                <div style={{ display: 'flex', 'paddingTop': '10px', 'paddingRight': '10px' }}>
                    <div>Gallery</div>
                    <div style={{ marginLeft: 'auto' }}><CloseIcon onClick={() => { galleryLightbox.toggle(false) }} /></div>
                </div>
                <ImageList sx={{ width: '93%', height: '100%', paddingRight: '15px' }} className='style-1'>
                    {favoriteGallerySelector?.map((item) => (
                        <ImageListItem key={item.conversationID}>
                            <img
                                src={`${item.imageURL}`}
                                alt={item.conversationID}
                                loading="lazy"
                            />
                            <StarIcon className='fav-star gallery' style={{ position: 'absolute' }} onClick={($event) => { deleteFavImage(item) }} />
                            <ImageListItemBar
                                title={new Date(item.time).toDateString()}
                                subtitle={item.messageText}
                                actionIcon={
                                    <>
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.messageText}`}
                                        >
                                            <DownloadIcon onClick={() => { downloadImage(item.imageURL || "") }} />
                                        </IconButton>
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.messageText}`}
                                        >
                                            {/* <VisibilityOffIcon onClick={() => { deleteFavImage(item) }} /> */}
                                        </IconButton>
                                    </>
                                }
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </div>
        </div>
    )
}