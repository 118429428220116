import { createSlice, configureStore } from '@reduxjs/toolkit';
import { ISession, IMessage, IFavImage } from '../models/message.model';
import { IProfile } from '../models/profile';
import { createSelector } from 'reselect';
import nlpService from '../services/nlp.service';

interface NLPState {
    sessions: ISession[];
    currentSessionId: string | null;
    sessionMessages: IMessage[];
    favoriteGallery: IMessage[];
    isLoadingSession: boolean;
    profile: any;
    token: string | undefined;
    serverState: {
        isConnected: boolean,
        reconnectionRetry: number,
        maxRetries: number
    }
}

const initialState: NLPState = {
    sessions: [],
    currentSessionId: "",
    sessionMessages: [],
    profile: {},
    favoriteGallery: [],
    isLoadingSession: false,
    token: undefined,
    serverState: {
        isConnected: false,
        reconnectionRetry: 0,
        maxRetries: 3
    }
};

const nlpSlice = createSlice({
    name: 'NLP',
    initialState,
    reducers: {
        setServerConnected: (state, action) => {
            state.serverState = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
        addChatSessions: (state, action) => {
            state.sessions = action.payload;
        },
        addChatSession: (state, action) => {
            const session: ISession = action.payload;
            state.sessions = [...state.sessions, session];
            state.sessionMessages = [];
            state.currentSessionId = action.payload._id;
        },
        updateChatSession: (state, action) => {
            state.sessions.forEach((session: ISession) => {
                if (session._id === action.payload._id) {
                    session = action.payload;
                }
            });
        },
        updateFavoriteGallery: (state, action) => {
            state.favoriteGallery = action.payload.favArray;
        },
        updateChatSessionTitle: (state, action) => {
            state.sessions.forEach((session: ISession) => {
                if (session._id === action.payload.id) {
                    session.title = action.payload.title;
                }
            });
            nlpService.subscribeToTitleChange.next({ id: action.payload.id, title: action.payload.title });
        },
        deleteChatSession: (state, action) => {
            if (action.payload === state.currentSessionId) {
                state.currentSessionId = null;
                state.sessionMessages = [];
            }
            // state.sessions = state.sessions.filter(item => item._id !== action.payload);
            const index = state.sessions.findIndex(session => session._id === action.payload);
            state.sessions.splice(index, 1);
        },
        setCurrentChatId: (state, action) => {
            state.currentSessionId = action.payload.chatId;
        },
        addMessage: (state, action) => {
            const message: IMessage = action.payload;
            if (message.conversationID === state.currentSessionId) {
                state.sessionMessages = [...state.sessionMessages, message];
            }
        },
        addMessages: (state, action) => {
            state.sessionMessages = action.payload;
        },
        updateMessage: (state, action) => {
            const newArray = state.sessionMessages.map(msg =>
                msg._id === action.payload._id ? (action.payload) : (msg)
            )
            state.sessionMessages = newArray;
        },
        toggleSessionLoading: (state, action) => {
            state.isLoadingSession = action.payload;
        },
        setPendingReplay: (state, action) => {
            const currentSession = state.sessions.find(session => session._id === state.currentSessionId);
            if (currentSession !== undefined) {
                currentSession.pendingReplay = action.payload;
            }
        }
    }
});

export const {
    setServerConnected,
    setToken,
    setProfile,
    addChatSessions,
    addChatSession,
    updateChatSession,
    updateChatSessionTitle,
    updateFavoriteGallery,
    toggleSessionLoading,
    deleteChatSession,
    setCurrentChatId,
    addMessage,
    addMessages,
    updateMessage,
    setPendingReplay } = nlpSlice.actions;

export const getProfile = (state: NLPState) => {
    return state.profile;
};

export const getAllSessions = (state: NLPState) => {
    return state.sessions;
};

export const getTodaySessions = createSelector(
    [getAllSessions],
    (sessions) => {
        const currentDate = new Date();
        const todaySessions = sessions.filter(session => {
            const sourceDate = new Date(session.last_update || session.creationDate);
            return sourceDate.toDateString() === currentDate.toDateString();
        });
        return todaySessions;
    }
);

export const getYesterdaySessions = createSelector(
    [getAllSessions],
    (sessions) => {
        const currentDate = new Date();
        const yesterday = new Date();
        yesterday.setDate(currentDate.getDate() - 1);
        return sessions.filter(session => {
            const sourceDate = new Date(session.last_update || session.creationDate);
            return sourceDate.toDateString() === yesterday.toDateString();
        });
    }
);

export const getMounthSessions = createSelector(
    [getAllSessions],
    (sessions) => {
        const currentDate = new Date();
        const yesterday = new Date();
        yesterday.setDate(currentDate.getDate() - 1);
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);

        return sessions.filter(session => {
            const sourceDate = new Date(session.last_update || session.creationDate);
            return (
                sourceDate.toDateString() !== currentDate.toDateString() &&
                sourceDate.toDateString() !== yesterday.toDateString() &&
                sourceDate >= thirtyDaysAgo
            );
        });
    }
);

export const getIsPendingReplay = (state: NLPState) => {
    const currentSession = state.sessions.find(session => session._id === state.currentSessionId);
    return currentSession?.pendingReplay;
};

export const getSessionId = (state: NLPState) => {
    return state.currentSessionId;
};

export const getAllMessages = (state: NLPState) => {
    return state.sessionMessages;
};

export const getToken = (state: NLPState) => {
    return state.token;
};

export const getServerState = (state: NLPState) => {
    return state.serverState;
};

export const getIsSessionLoading = (state: NLPState) => {
    return state.isLoadingSession;
};

export const getFavoriteGallery = (state: NLPState) => {
    return state.favoriteGallery;
};

export const nlpStore = configureStore({
    reducer: nlpSlice.reducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

/// Testing
export const getLastUploadedImage = (state: NLPState) => {
    const currentSession = state.sessions.find(session => session._id === state.currentSessionId);
    return currentSession?.lastImageSrc;
}; 