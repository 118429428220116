import socketIOClient from "socket.io-client"
import { SocketIoEventName } from "../models/socketIO.model";
import { IMessage, ISession } from "../models/message.model";
import { nlpStore, addMessage, setPendingReplay, setServerConnected, updateChatSessionTitle } from "../store/npl.slice"
import { config } from "../config/config";

class SocketIOService {
    private NLPEP: string | undefined = config.socketIOEP;
    private static instance: SocketIOService | null;
    // private namespace = "/nlp-hub";

    static getInstance(): SocketIOService {
        if (!this.instance) {
            this.instance = new SocketIOService();
        }
        return this.instance;
    }
    socket: any;

    connectToSocketIo = (tok: string) => {
        this.socket = socketIOClient(`${this.NLPEP}` || "", { //path: this.namespace,            
            reconnection: true,
            extraHeaders: {
                Authorization: `Bearer ${tok}`,
                "Content-Type": "application/json",
            }
        });
        nlpStore.dispatch(setServerConnected({ isConnected: true, reconnectionRetry: 0 }));
        this.subscribeToEvents();
    }

    subscribeToEvents = () => {
        this.socket.on(SocketIoEventName.message, (message: IMessage) => {
            nlpStore.dispatch(setPendingReplay(false));
            nlpStore.dispatch(addMessage(message));
        });
        this.socket.on(SocketIoEventName.info, (message: IMessage) => {
            console.log(message);
        });
        this.socket.on(SocketIoEventName.conversation, (session: ISession) => {
            nlpStore.dispatch(updateChatSessionTitle({ id: session._id, title: session.title }));
        });
        this.socket.io.on("ping", () => {
            console.log("Keep-Alive");
            //nlpStore.dispatch(setServerConnected({ isConnected: true, reconnectionRetry: 0 }));
        });
        this.socket.io.on("reconnect", (attempt: number) => {
            console.log("Reconnected");
            nlpStore.dispatch(setServerConnected({ isConnected: true, reconnectionRetry: 0 }));
        });
        this.socket.io.on("reconnect_attempt", (attempt: number) => {
            console.log(`Attempt Reconnect - ${attempt}`);
            nlpStore.dispatch(setServerConnected({ isConnected: false, reconnectionRetry: attempt }));
        });
        this.socket.io.on("reconnect_failed", () => {
            console.log("Reconnect Failed");
            nlpStore.dispatch(setServerConnected({ isConnected: false, reconnectionRetry: 4 }));
        });
    };
}

export default SocketIOService.getInstance();