import React, { createRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MessageSource, IMessage } from "../../models/message.model"
import { useSelector } from 'react-redux';
import { getAllMessages, getIsPendingReplay, getSessionId, getLastUploadedImage, getProfile, getIsSessionLoading, nlpStore } from "../../store/npl.slice"
import CircularProgress from '@mui/material/CircularProgress';
import appContext from '../../context/app.context';
import nlpService from '../../services/nlp.service';
import { useTranslation } from 'react-i18next';
import { User, useAuth0 } from '@auth0/auth0-react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useTheme } from 'styled-components';
import StyledDropzone from '../drop-zone/drop-zone';
import { ReactComponent as MyIcon } from '../../assets/images/clip.svg';
import { updateMessage } from '../../store/npl.slice';
import "./nlp-chat.css";
import ShareArrowSVG from '../share-arrow-svg/share-arrow-svg';
import { subscribeOn, Subscription } from 'rxjs';
import PendingMsgIcon from '../pending-msg-icon/pending-msg-icon';


function NLPChat() {
    const chatContainer = useRef<HTMLDivElement>(null);
    const { user } = useAuth0();
    const theme = useTheme();
    const { picture, sub } = user as User;
    const { t, i18n } = useTranslation();
    const { imageLightbox, emailLightbox }: any = useContext(appContext);
    const [userInput, setUserInput] = useState<string>("");
    const [imageHoverId, setImageHoverId] = useState<string>("");
    const [isDragDrop, setIsDragDrop] = useState(false);

    const profile = useSelector(getProfile);
    const isSessionLoading = useSelector(getIsSessionLoading);
    const sessionMessages: IMessage[] = useSelector(getAllMessages);
    const pendingReplay = useSelector(getIsPendingReplay);
    const sessionId = useSelector(getSessionId);
    const lastImageSrc = useSelector(getLastUploadedImage);
    const subscription: Subscription = new Subscription();


    useEffect(() => {

        return () => {
            subscription.unsubscribe()
        }
    }, []);

    useEffect(() => {
        const fileInput = document.getElementById("fileInput");
        fileInput?.addEventListener('change', simulateFileDrop);

        const chatView = document.getElementsByTagName('body')[0];
        chatView?.addEventListener('dragenter', onDragEnter);
        chatView?.addEventListener('drop', onDropEvent);

        console.log("Initialized!");

        return () => {
            fileInput?.removeEventListener('change', simulateFileDrop);
            chatView?.removeEventListener('dragenter', onDragEnter);
            chatView?.removeEventListener('drop', onDropEvent);
        };
    }, [sessionId]);

    useEffect(() => {
        if (isSessionLoading) {
            nlpService.toggleIsSessionLoading(false);
        }
    }, [isSessionLoading])

    useEffect(() => {
        autoScrollDown();
    }, [sessionMessages])

    const simulateFileDrop = (event: any) => {
        onFileDrop({ dataTransfer: event.target }, sessionId || "");
    };

    const onDragEnter = (event: any) => {
        (chatContainer.current as HTMLElement).style.opacity = '.1';
        setIsDragDrop(true);

        event.preventDefault();
        event.stopImmediatePropagation();
    };

    const onDropEvent = (event: any) => {
        (chatContainer.current as HTMLElement).style.opacity = '1';
        setIsDragDrop(false);

        // event.preventDefault();
        // event.stopImmediatePropagation();
    };

    const submitMessgae = () => {
        if (userInput === "sessionid") {
            alertSessionID();
            return;
        }
        const message: IMessage = {
            messageSrc: MessageSource.User,
            time: new Date(Date.now()).toString(),
            messageText: userInput,
            conversationID: sessionId || ""
        };
        setUserInput("");
        nlpService.addMessage(message);
        nlpService.sendMessage(message);
    };

    const preventDefault = (e: any) => {
        let event = e as Event;
        event.stopPropagation();
        event.preventDefault();
    };

    const onFileDrop = (e: any, sessionId: string) => {
        if (!sessionId) {
            console.log("No Session ID")
            return;
        }

        const imageFile = e.dataTransfer.files[0];
        let messageIN: IMessage = {
            messageSrc: MessageSource.User,
            imageURL: URL.createObjectURL(imageFile),
            conversationID: sessionId || "",
            time: new Date(Date.now()).toString()
        };
        nlpService.addMessage(messageIN);

        let messageOUT: IMessage = {
            messageSrc: MessageSource.User,
            messageImage: imageFile,
            conversationID: sessionId || "",
            time: new Date(Date.now()).toString()
        };
        nlpService.sendMessage(messageOUT);
    }

    const autoScrollDown = () => {
        const timer = () => setTimeout(() => {
            chatContainer.current?.scrollTo(0, chatContainer.current.scrollHeight);
        }, 600);
        timer();
        subscription.add(timer);
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            if (userInput)
                submitMessgae();
        }
    };

    const previewImage = (imageSrc: string | undefined) => {
        imageLightbox.setSrc(imageSrc);
        imageLightbox.toggle(true);
    };

    const alertSessionID = () => alert(`Current session ID: ${sessionId}`);

    const toggleFavImage = async (event: any, msg: IMessage) => {
        event.stopPropagation();
        try {
            if (msg._id) {
                // update message isFavorite and 
                const response = !msg.isFavorite ?
                    await nlpService.addFavImage({ ...msg, isFavorite: true }) :
                    await nlpService.deleteFavImage({ ...msg, isFavorite: false });
                if (response.status === 200) {
                    // return updated message to client
                    nlpStore.dispatch(updateMessage(response?.data));
                }
            } else {
                throw "No Message ID";
            }

        } catch (error) { }
    };

    const onBrowseFile = () => {
        const fileInput = document.getElementById("fileInput");
        fileInput?.click();
    };

    const handleMouseEnter = (msg: IMessage) => {
        emailLightbox.setEmailObject(msg);
        setImageHoverId(msg._id || "");
    };

    const handleMouseLeave = () => {
        setImageHoverId("");
    };

    const swipeStart = (e: any, msg: any) => {
        e.stopPropagation();
        e.preventDefault();
        startX = e.touches[0].clientX;
    };
    const swipeMove = (e: any, msg: any) => {
        const touch = e.touches[0];
        const touchX = touch.clientX;

        const deltaX = touchX - startX;

        if (deltaX > -50) { // Adjust this threshold as needed
            showSwipeIcon(msg._id);
        }
    };
    const swipeEnd = (e: any, msg: any) => {
        hideSwipeIcon(e);
    };
    function showSwipeIcon(msg: any) {
        emailLightbox.setEmailObject(msg);
        setImageHoverId(msg._id);
    }

    function hideSwipeIcon(e: any) {
        // debugger
        e.target.classList.add('swipe-active');
        // setImageHoverId("");
    }
    let startX: any;
    return (
        <main className="chat-view">
            {isDragDrop && sessionId !== "" &&
                <div style={{ borderRadius: '5px', zIndex: 2, position: 'absolute', width: '70%', height: '70%', backgroundColor: 'transparent', marginLeft: '5%', alignContent: 'center' }}>
                    <StyledDropzone onDrop={(event: any) => { onFileDrop({ dataTransfer: { files: event } }, sessionId || "") }} onMouseLeave={(event: any) => { onDropEvent(event) }} />
                </div>
            }
            <div id="style-1" className={`chatContainer section`} ref={chatContainer}>
                {
                    isSessionLoading && <CircularProgress sx={{
                        margin: "0 auto",
                        marginTop: "30vh"
                    }} />
                }
                {
                    !isSessionLoading && sessionMessages?.map((msg: IMessage, index: number) => {
                        const msgDate = new Date(msg.time);
                        return (
                            <div key={index} className={`chatRow ${msg.messageSrc === MessageSource.Application ? '' : 'rev'}`} onTouchStart={(e) => { swipeStart(e, msg) }} onTouchMove={(e) => { swipeMove(e, msg) }} onTouchEnd={(e) => { swipeEnd(e, msg) }}>
                                <div className={`profileBase ${msg.messageSrc === MessageSource.Application ? 'botImg' : 'profileImg'}`} style={
                                    msg.messageSrc === MessageSource.User && {
                                        backgroundImage: `url(${profile.picture || picture})`
                                    } || {}}></div>
                                {
                                    msg.imageURL &&
                                    <div className={`imageContainerBase ${msg.messageSrc === MessageSource.Application ? 'applicationImage' : 'userImage'}`} onMouseOver={() => { handleMouseEnter(msg) }} style={{
                                        backgroundColor: msg.messageSrc === MessageSource.Application ? theme.chatBubbleColorNLP : theme.chatBubbleColorUser,
                                        color: msg.messageSrc === MessageSource.Application ? theme.chatTextColorNLP : theme.chatTextColorUser
                                    }}>
                                        <div className={`imageMsg`} onClick={(event) => { previewImage(msg.imageURL) }}>
                                            <img src={msg.imageURL} style={{ width: '90%', height: '100%', display: 'block', margin: '0 auto', cursor: 'pointer' }}></img>
                                            {
                                                msg.isFavorite !== undefined && (
                                                    msg.isFavorite ?
                                                        <StarIcon className='fav-star' onClick={($event) => { toggleFavImage($event, msg) }} /> :
                                                        <StarBorderIcon className='fav-star' onClick={($event) => { toggleFavImage($event, msg) }} />
                                                )
                                            }
                                        </div>
                                        <div className='imageTextContainer'>
                                            <span>{msg.messageText}</span>
                                            <span>{msgDate.getHours()}:{msgDate.getMinutes() < 10 ? `0${msgDate.getMinutes()}` : msgDate.getMinutes()}</span>
                                        </div>
                                    </div>
                                }
                                {
                                    msg.messageSrc === MessageSource.Application && msg.imageURL && imageHoverId === msg._id &&
                                    <ShareArrowSVG onClick={() => { emailLightbox.toggle(true) }}></ShareArrowSVG>
                                }
                                {
                                    !msg.imageURL &&
                                    <div style={{
                                        backgroundColor: msg.messageSrc === MessageSource.Application ? theme.chatBubbleColorNLP : theme.chatBubbleColorUser,
                                        color: msg.messageSrc === MessageSource.Application ? theme.chatTextColorNLP : theme.chatTextColorUser,
                                        width: 'fit-content'
                                    }}
                                        className={msg.messageSrc === MessageSource.Application ? 'applicationMsg' : 'userMsg'}>
                                        <span>{msg.messageText}</span>
                                        <span>{msgDate.getHours()}:{(msgDate.getMinutes() < 10 ? '0' : '') + (msgDate.getMinutes())}</span>
                                    </div>
                                }
                            </div>
                        )
                    })}
                {
                    pendingReplay &&
                    <div className={`chatRow`}>
                        <div className={`profileBase botImg`}></div>
                        <PendingMsgIcon></PendingMsgIcon>
                        {/* <div className={`applicationMsg botIsTyping`}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div> */}
                    </div >
                }
            </div>
            <div className={`search`}>
                <input type="file" id="fileInput" style={{ display: 'none' }} accept="image/png, image/jpeg" />
                <button className={`searchButton`} onClick={onBrowseFile} style={{ position: 'absolute', cursor: 'pointer' }}>
                    <svg className={`searchIcon`} aria-hidden="true" viewBox="0 -20 120 120">
                        <g>
                            <path xmlns="http://www.w3.org/2000/svg" d="M75.2,20.8c-7.4-7.4-19.4-7.4-26.8,0l-32,32c-7.4,7.4-7.4,19.4,0,26.8s19.4,7.4,26.8,0l24-24
                            c5.5-5.5,5.5-14.3,0-19.8s-14.3-5.5-19.8,0l-21,21c-1.1,1.1-1.1,2.9,0,4s2.9,1.1,4,0l21-21c3.7-3.7,9.7-3.7,13.4,0
                            s3.7,9.7,0,13.4l-24,24c-5.5,5.5-14.3,5.5-19.8,0s-5.5-14.3,0-19.8l32-32c5.5-5.5,14.3-5.5,19.8,0s5.5,14.3,0,19.8
                            c-1.1,1.1-2.9,1.1-4,0s-1.1-2.9,0-4C79,30.5,79,25.5,75.2,20.8z"/>
                        </g>
                    </svg>
                </button>
                <input
                    type="text"
                    value={userInput}
                    onChange={(event) => {
                        setUserInput(event.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    className={`searchInput`}
                    placeholder={t("Write to ImiSight chat")} />
                <button className={`searchButton`} onClick={submitMessgae} disabled={pendingReplay || !sessionId || !userInput}>
                    <svg className={`searchIcon`} aria-hidden="true" viewBox="0 -10 100 100">
                        <g>
                            <path xmlns="http://www.w3.org/2000/svg" id="arrow" fill="#0070C0" d="M 100,50 L 0,0 L 30,50 L 0,100" />
                        </g>
                    </svg>
                </button>
            </div>
        </main >
    )
}

export default NLPChat;
