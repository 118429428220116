'use client'
import { useEffect, useState, useContext } from 'react';
import appContext from '../../context/app.context';
import nlpService from '../../services/nlp.service';
import { Subscription } from 'rxjs';
import './email-panel.css';
import { IMessage } from '../../models/message.model';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from 'styled-components';

const EmailPanel = () => {
    const theme = useTheme();
    const subscriptions: Subscription = new Subscription();
    type mailStatusType = "" | "pending" | "sent" | "error";
    const { emailLightbox }: any = useContext(appContext);
    const [mailStatus, setMailStatus] = useState<{ status: mailStatusType }>({ status: "" });
    const [privacyConfirm, setPrivacyConfirm] = useState(true);
    const [emailSubmitState, setEmailSubmitState] = useState(true);
    const [emailData, setEmailData] = useState({
        firstName: "",
        lastName: "",
        company: "",
        emailAddress: ""
    });

    useEffect(() => {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (regex.test(emailData.emailAddress)) {
            setEmailSubmitState(false);
        } else {
            setEmailSubmitState(true);
        }
    }, [emailData.emailAddress]);

    const sendEmail = () => {
        if (!emailLightbox.emailObj) {
            return;
        }

        setEmailSubmitState(true);
        nlpService.sendEmail(emailLightbox.emailObj, emailData).then(async (res) => {
            setMailStatus({ status: 'sent' });
            const startTimer = async () => {
                setTimeout(() => {
                    setMailStatus({ status: '' });
                    setEmailSubmitState(false);
                    emailLightbox.toggle(false);
                }, 5000);
            };
            await startTimer();
            subscriptions.add(startTimer);
        }).catch(async (error) => {
            console.log(error);
            setMailStatus({ status: 'error' });
            const startTimer = async () => {
                setTimeout(() => {
                    setMailStatus({ status: '' });
                    setEmailSubmitState(false);
                    emailLightbox.toggle(false);
                }, 5000);
            };
            await startTimer();
            subscriptions.add(startTimer);
        }).finally(() => {
            // setMailStatus({ status: '' });
            subscriptions.unsubscribe();
        });
    };

    return (
        <div id="lightbox">
            <div className="lightbox-container" style={{ height: '64vh' }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '5px', width: '45vw', padding: '15px 15px 0 15px' }}>
                    <div style={{ maxWidth: '90%', width: '100%' }}><span style={{ color: 'white', fontWeight: 'bold', float: 'right', cursor: 'pointer' }} onClick={() => { emailLightbox.toggle(false) }}>X</span></div>
                    {
                        mailStatus.status === "sent" &&
                        <div className="form" style={{
                            backgroundColor: theme.chatBubbleColorNLP,
                            color: theme.chatTextColorNLP == "#000" ? "#fff" : theme.chatTextColorNLP
                        }}>
                            <img src='images/tick.png' style={{ width: '50px', margin: '0 auto' }} /><br />
                            <span style={{ display: 'block', margin: '0 auto' }}>Report sent successfully</span>
                        </div>
                    }
                    {
                        mailStatus.status === "error" &&
                        <div className="form" style={{
                            backgroundColor: theme.chatBubbleColorNLP,
                            color: theme.chatTextColorNLP == "#000" ? "#fff" : theme.chatTextColorNLP
                        }}>
                            <img src='images/x.png' style={{ width: '50px', margin: '0 auto' }} />
                            <span style={{ display: 'block', margin: '0 auto' }} >Error sending report</span>
                        </div>
                    }
                    {
                        mailStatus.status === "" &&
                        <form className="form" style={{
                            backgroundColor: theme.chatBubbleColorNLP
                        }}>
                            <div className="flex">
                                <label>
                                    <input className="input" type="text" placeholder="" style={{ width: '93%' }} onChange={event => {
                                        setEmailData(emailData => ({ ...emailData, firstName: event.target.value }));
                                    }} />
                                    <span style={{
                                        color:  "#fff"
                                    }}>First name</span>
                                </label>

                                <label>
                                    <input className="input" type="text" placeholder="" onChange={event => {
                                        setEmailData(emailData => ({ ...emailData, lastName: event.target.value }));
                                    }} />
                                    <span style={{
                                        color:  "#fff"
                                    }}>Last name</span>
                                </label>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <label style={{ width: '100%' }}>
                                    <input className="input" type="text" placeholder="" style={{ width: '100%' }} onChange={event => {
                                        setEmailData(emailData => ({ ...emailData, company: event.target.value }));
                                    }} />
                                    <span style={{
                                        color:  "#fff"
                                    }}>Company</span>
                                </label>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <label style={{ width: '100%' }}>
                                    <input className="input" type="email" placeholder="" style={{ width: '100%' }} onChange={event => {
                                        setEmailData(emailData => ({ ...emailData, emailAddress: event.target.value }));
                                    }} />
                                    <span style={{
                                       color:  "#fff"
                                    }}>Email</span>
                                </label>
                            </div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <input className="signin" type='checkbox' onChange={event => {
                                    setPrivacyConfirm(state => !state);
                                }} />
                                <span style={{
                                    color: theme.chatTextColorNLP
                                }}>I agree that my details will be saved by Imisight for future contact</span>
                            </div>
                            <button className="submit" style={{
                                color: 'black',
                                fontWeight: 'bold',
                                backgroundColor: theme.chatBubbleColorUser
                            }} disabled={emailSubmitState || privacyConfirm} onClick={sendEmail}>Submit</button>
                        </form>
                    }
                </div>
            </div>
        </div>
    )
};

export default EmailPanel;