export enum IMessageType {
    Base,
    Text,
    ImageAndText
}
export interface IMessage {
    _id?: string,
    messageId?: string,
    conversationID: string,
    time: string,
    messageSrc: MessageSource,
    imageURL?: string,
    messageImage?: Blob,
    messageText?: string,
    profileImageUrl?: string,
    isFavorite?: boolean
}

export interface IFavImage {
    session_id: string;
    message_id: string;
    title: string;
    subtitle: string;
    imageUrl: string;
}

export interface ISession {
    _id: string,
    title?: string,
    creationDate: string,
    lastImageSrc: string,
    last_update: string,
    pendingReplay: boolean
}

export enum MessageSource {
    User = "User",
    Application = "Application"
}