import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import NLPHeader from "./components/header/header.component"
import NLPChat from './components/nlp-chat/nlp-chat.component';
import appContext from './context/app.context';
import ChatPanel from './components/npl-chat-panel/nlp-chat-panel.component';
import SettingsPanel from './components/settings-panel/settings-panel.component';
import GalleryPanel from './components/gallery-panel/gallery-panel.component';
import LoginPage from '../src/pages/login/login';
import nlpService from './services/nlp.service';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from 'react-redux';
import { getServerState, getToken } from './store/npl.slice';
import socketIOService from './services/socketIO.service';
import { lightTheme, darkTheme } from './theme';
import { useDarkMode } from './hooks/themeMode';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import i18n from './i18n';
import './App.css';
import { Subscription } from 'rxjs';
import { IMessage } from './models/message.model';
import EmailPanel from './components/email-panel/email-panel';

function App() {
  const [imageLightboxDisplay, setImageLightBoxDisplay] = useState<boolean>(false);
  const [emailLightboxDisplay, setEmailLightBoxDisplay] = useState<boolean>(false);
  const [emailObj, setEmailObject] = useState<IMessage | null>(null);
  const [settingsLightboxDisplay, setSettingsLightBoxDisplay] = useState<boolean>(false);
  const [galleryLightboxDisplay, setGalleryLightBoxDisplay] = useState<boolean>(false);
  const [theme, setMode] = useDarkMode();
  const [imageToShow, setImageToShow] = useState("");
  const { isAuthenticated, isLoading, error, getAccessTokenSilently }: any = useAuth0();
  const token = useSelector(getToken);
  const serverState = useSelector(getServerState);
  const { t } = useTranslation();
  const MAX_RETRIES = 3;
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  useEffect(() => {
    (async function getToken() {
      try {
        const tok = await getAccessTokenSilently();
        nlpService.setToken(tok);
      } catch (error) {
        nlpService.setToken("");
      }
    })()
  }, [isAuthenticated]);

  useEffect(() => {
    if (token) {
      socketIOService.connectToSocketIo(token);
      nlpService.getLocale().then((locale) => {
        i18n.changeLanguage(locale || "en");
      });
    }
  }, [token]);

  const downloadImage = async (e: any) => {
    let event = e as Event;
    e.stopPropagation();
    e.preventDefault();

    nlpService.downloadImage(imageToShow).then((response) => {
      const type: string = response.headers['content-type'];
      const imageBlob = new Blob([response.data], { type: `image/${type}` });
      const imageUrl = URL.createObjectURL(imageBlob);

      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(imageUrl);
    });
  };

  return (
    <>
      {error && <p>Auth Error</p>}
      {!error && isLoading &&
        <div className='loadingContainer'>
          <div className="honeycomb">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>}
      {!error && !isLoading && isAuthenticated &&
        <ThemeProvider theme={themeMode}>
          <appContext.Provider value={{
            imageLightbox: {
              toggle: setImageLightBoxDisplay,
              setSrc: setImageToShow
            },
            emailLightbox: {
              toggle: setEmailLightBoxDisplay,
              emailObj,
              setEmailObject
            },
            settingsLightbox: {
              toggle: setSettingsLightBoxDisplay
            },
            galleryLightbox: {
              toggle: setGalleryLightBoxDisplay
            },
            appTheme: {
              setTheme: setMode,
              theme
            }
          }}>
            {
              emailLightboxDisplay ?
                <EmailPanel /> : null
            }
            {imageLightboxDisplay ?
              <div id="lightbox" onClick={() => { setImageLightBoxDisplay(false) }}>
                <div className="lightbox-container">
                  <img id="lightbox-img" src={imageToShow}></img>
                  <div className={`lightbox-controls`}>
                    <DownloadIcon onClick={(event) => { downloadImage(event) }} sx={{
                      fill: "gray",
                      cursor: "pointer",
                      backgroundColor: "white",
                      borderRadius: "4px",
                      padding: "5px",
                    }}></DownloadIcon>
                    <SendIcon sx={{
                      fill: "gray",
                      cursor: "pointer",
                      backgroundColor: "white",
                      borderRadius: "4px",
                      padding: "5px",
                    }}></SendIcon>
                  </div>
                </div>
              </div>
              : ''}
            {settingsLightboxDisplay ?
              <SettingsPanel></SettingsPanel>
              : ''}
            {galleryLightboxDisplay ?
              <GalleryPanel></GalleryPanel>
              : ''}
            {
              !error && !isLoading && serverState.isConnected &&
              <div className={`app-container`}>
                <ChatPanel></ChatPanel>
                <div className={`chat-container`} style={{ backgroundImage: `url(${themeMode.backgroundPath})`, paddingLeft: themeMode.chatContainerPaddingLeft, marginLeft: themeMode.chatContainerMarginLeft }}>
                  <NLPHeader></NLPHeader>
                  <NLPChat></NLPChat>
                </div>
              </div> ||
              <div className="lost-connection">
                <span>{t('Disconnected from server')}</span>
                {serverState.reconnectionRetry <= MAX_RETRIES &&
                  <span>{`${t('Attempting reconnect')} (${serverState.reconnectionRetry})`}</span>
                }
                {serverState.reconnectionRetry > MAX_RETRIES &&
                  <a href='#' onClick={(event) => { window.location.reload() }}><span>{`${t('Try reload')}`}</span></a>
                }
              </div>
            }

          </appContext.Provider >
        </ThemeProvider >
      }
      {
        !isAuthenticated && !isLoading &&
        <LoginPage></LoginPage>
      }
    </>
  )
}

export default App;
