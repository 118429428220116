'use client'

import { useTheme, styled } from 'styled-components';
import './pending-msg-icon.css';

const PendingMsgIcon = () => {
    const theme = useTheme();

    const LoaderDot = styled.div`
    &::before{
        background-color: ${theme.shareArrow};
    }`;

    return (
        <div className="leap-frog">
            <LoaderDot className='leap-frog__dot' />
            <LoaderDot className='leap-frog__dot' />
            <LoaderDot className='leap-frog__dot' />
        </div>
    )
};

export default PendingMsgIcon;