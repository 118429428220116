import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButton = () => {
    const { t } = useTranslation();
    const { logout } = useAuth0();

    return (
        <Button component="button" variant="contained" startIcon={<LogoutIcon />} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
            {t("Log Out")}
        </Button>
    );
};

export default LogoutButton;