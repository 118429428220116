import React from 'react';
import { useTheme } from 'styled-components';

const ShareArrowSVG = ({ onClick }: { onClick: Function }) => {
    const theme = useTheme();
    
    return (
        <svg onClick={() => { onClick() }} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50" viewBox="0 0 1000 1000" xmlSpace="preserve">
            <desc>Created with Fabric.js 5.2.4</desc>
            <defs></defs>
            <rect x="0" y="0" width="100%" height="100%" fill="transparent"></rect>
            <g transform="matrix(1 0 0 1 540 540)" id="8b257998-1d95-4642-a726-54a1ce01dea0">
                <rect
                    style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "butt",
                        strokeDashoffset: 0,
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                        fill: "rgb(255,255,255)",
                        fillRule: "nonzero",
                        opacity: 1,
                        visibility: "hidden",
                    }}
                    vectorEffect="non-scaling-stroke"
                    x="-540"
                    y="-540"
                    rx="0"
                    ry="0"
                    width="1080"
                    height="1080"
                />
            </g>
            <g transform="matrix(1 0 0 1 540 540)" id="bd185c76-60f7-43b9-955e-8bae61285c38"></g>
            <g transform="matrix(1 0 0 1 569.59 557.75)" id="14eb74d8-ce67-42c1-9ac3-a7e665bd2f94">
                <path
                    style={{
                        strokeWidth: 0,
                        strokeDasharray: "none",
                        strokeLinecap: "butt",
                        strokeDashoffset: 0,
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 4,
                        fill: theme.shareArrow,
                        fillRule: "nonzero",
                        opacity: 1,
                    }}
                    vectorEffect="non-scaling-stroke"
                    transform="translate(-256, -256)"
                    d="M 512 230.431 L 283.498 44.621 L 283.498 139.428 C 60.776 141.244 -21.842 307.324 4.826 467.379 C 53.522 367.886 154.74099999999999 328.702 283.49800000000005 324.23900000000003 L 283.49800000000005 416.242 L 512 230.431 z"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
};

export default ShareArrowSVG;
