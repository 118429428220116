import React, { useContext, useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from "@mui/material";
import GridViewIcon from '@mui/icons-material/GridView';
import { getSessionId, getTodaySessions, getYesterdaySessions, getMounthSessions } from "../../store/npl.slice";
import { useSelector } from "react-redux";
import nlpSrv from '../../services/nlp.service';
import appContext from "../../context/app.context";
import { useTranslation } from 'react-i18next';
import { TypeAnimation } from "react-type-animation";
import { Speed } from "../../models/types";
import { useTheme } from 'styled-components';
import Logo from "../gallery-icon/gallery-icon";
import { Subscription } from 'rxjs';
import { ReactComponent as Icon } from '../../assets/images/light-mode/gallery.svg';
import "./nlp-chat-panel.css";

function ChatPanel() {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const todaySessions = useSelector(getTodaySessions);
    const yesterdaySessions = useSelector(getYesterdaySessions);
    const mounthSessions = useSelector(getMounthSessions);
    const sessionId = useSelector(getSessionId);
    const { settingsLightbox, galleryLightbox }: any = useContext(appContext);
    const [editTitleForSessionId, setEditTitleForSessionId] = useState<null | string>(null);
    const [typeSpeed, setTypeSpeed] = useState<Speed>(99);
    let subscription: Subscription = new Subscription();
    const [sessionRename, setSessionRename] = useState("");

    useEffect(() => {
        nlpSrv.getChatSessions();
        subscription = nlpSrv.subscribeToTitleChange.subscribe(sessionTitleChanged);
        nlpSrv.getFavoriteGalery();
        openChat();

        return () => {
            if (subscription && !subscription.closed) {
                subscription.unsubscribe();
            }
        }
    }, []);

    // override a bug where title is not rerenders after serverPush caused by MUI TypeAnimation component
    const sessionTitleChanged = (session: any | null) => {
        if (!session) {
            return;
        } else {
            const element = document.getElementById(`session-${session.id}`);
            if (element) {
                try {
                    setTypeSpeed(50);
                    element.getElementsByTagName("span")[0].innerHTML = session.title;
                    const elem = element.getElementsByClassName("chat-title")[0] as HTMLDivElement;
                    elem.style.visibility = 'hidden';
                    elem.style.visibility = 'visible';
                    setTimeout(() => {
                        setTypeSpeed(99);
                    }, 3000);
                } catch (err) {

                }
            }
        }
    }

    const openChat = () => {
        nlpSrv.openNewChatSession();
        setTypeSpeed(50);
        setTimeout(() => {
            setTypeSpeed(99);
        }, 3000);
    };

    const toggleChatSessionById = (chatSessionId: string) => {
        nlpSrv.toggleIsSessionLoading(true);
        nlpSrv.setCurrentChatId(chatSessionId);
        nlpSrv.getMessagesBySessionId(chatSessionId);
    }

    const deleteSession = (event: Event, sessionId: string) => {
        event.preventDefault();
        event.stopPropagation();

        if (sessionId)
            nlpSrv.deleteSessionById(sessionId);
    };

    const toggleTitleEdit = (event: any, id: string | null) => {
        event.preventDefault();
        event.stopPropagation();
        setEditTitleForSessionId(id);

        if (id) {
            event.target?.focus();
        }

    };

    const updateSessionTitle = (event: any, title: string, id: string) => {
        event.preventDefault();
        event.stopPropagation();
        // const title = event.target.parentElement.children[0]?.getElementsByTagName('input')[0].value;
        if (title && title.length > 0) {
            nlpSrv.updateSessionTitle(id, title).then(res => {
                if (res) {
                    setEditTitleForSessionId(null);
                }
            });
        }
    };

    return (
        <div style={{ backgroundColor: theme.sidePanelBGColor, color: theme.sidePanelTitle, boxShadow: theme.sidePanelBoxShadow }} className={`chat-panel-container section`}>
            <div className="new-chat-container">
                <div className={`new-chat-box`} onClick={(event) => { openChat() }}>
                    <img src={theme.sidePanelNewChatIconPath} style={{ width: '20px' }}></img>
                    <div className={`new-chat-lbl`}>
                        <span style={{ color: theme.sidePanelNewChatColor }}>{t('Start new chat')}</span>
                    </div>
                </div>
            </div>
            <div className="style-1" style={{ overflowY: 'auto' }}>
                <div><span className={`sortby-style`}>{t('Today')}</span></div>
                {todaySessions?.map((session: any) => {
                    if (!session.title)
                        return;
                    return (
                        <div key={session._id}>
                            <div id={`session-${session._id}`} className={`chat-box ${sessionId === session._id ? 'selected-chat' : ''}`} onClick={(event) => { toggleChatSessionById(session._id) }}>
                                {
                                    editTitleForSessionId !== session._id &&
                                    <div className={`chat-title`} style={{ color: theme.sidePanelChatTitle }}>
                                        {
                                            <TypeAnimation
                                                sequence={[session.title, 1000]}
                                                wrapper="span"
                                                speed={typeSpeed}
                                                cursor={false}
                                            />
                                        }
                                    </div>
                                }
                                {
                                    editTitleForSessionId === session._id &&
                                    <>
                                        <div className={`chat-title edit-mode`}>
                                            <TextField
                                                id="title-edit-text"
                                                defaultValue={session.title}
                                                variant="standard"
                                                focused={true}
                                                onChange={(event) => { setSessionRename(event.target.value) }}
                                                onClick={(event) => { event.stopPropagation(); }}
                                            />
                                        </div>
                                        <CloseIcon className="chat-actions cancel" onClick={(event: any) => { toggleTitleEdit(event, null) }} />
                                        <CheckIcon className="chat-actions ok" onClick={(event: any) => { updateSessionTitle(event, sessionRename, session._id) }} />
                                    </>

                                }
                                {
                                    sessionId === session._id && editTitleForSessionId !== session._id &&
                                    <EditIcon className="chat-actions edit" onClick={(event: any) => { toggleTitleEdit(event, session._id) }} />
                                }
                                {
                                    editTitleForSessionId !== session._id &&
                                    <DeleteOutlineIcon className="chat-actions delete" onClick={(event: any) => { deleteSession(event, session._id) }} />
                                }
                            </div>
                        </div>
                    )
                })}
                {
                    yesterdaySessions?.length !== 0 &&
                    <>
                        <div><span className={`sortby-style`}>{t('Yesterday')}</span></div>
                        {yesterdaySessions?.map((session: any, index) => {
                            return (
                                <div key={session._id}>
                                    <div className={`chat-box ${sessionId === session._id ? 'selected-chat' : ''}`} onClick={(event) => { toggleChatSessionById(session._id) }}>
                                        {
                                            editTitleForSessionId !== session._id &&
                                            <div className={`chat-title`} style={{ color: theme.sidePanelChatTitle }}>
                                                <span>{session.title}</span>
                                            </div>
                                        }
                                        {
                                            editTitleForSessionId === session._id &&
                                            <>
                                                <div className={`chat-title edit-mode`}>
                                                    <TextField
                                                        id="title-edit-text"
                                                        defaultValue={session.title}
                                                        variant="standard"
                                                        focused={true}
                                                        onChange={(event) => { setSessionRename(event.target.value) }}
                                                        onClick={(event) => { event.stopPropagation(); }}
                                                    />
                                                </div>
                                                <CloseIcon className="chat-actions cancel" onClick={(event: any) => { toggleTitleEdit(event, null) }} />
                                                <CheckIcon className="chat-actions ok" onClick={(event: any) => { updateSessionTitle(event, sessionRename, session._id) }} />
                                            </>

                                        }
                                        {
                                            sessionId === session._id && editTitleForSessionId !== session._id &&
                                            <EditIcon className="chat-actions edit" onClick={(event: any) => { toggleTitleEdit(event, session._id) }} />
                                        }
                                        {
                                            editTitleForSessionId !== session._id &&
                                            <DeleteOutlineIcon className="chat-actions delete" onClick={(event: any) => { deleteSession(event, session._id) }} />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </>
                }
                {
                    mounthSessions?.length !== 0 &&
                    <>
                        <div><span className={`sortby-style`}>{t('Previous 30 Days')}</span></div>
                        {mounthSessions?.map((session: any, index) => {
                            return (
                                <div key={session._id}>
                                    <div className={`chat-box ${sessionId === session._id ? 'selected-chat' : ''}`} onClick={(event) => { toggleChatSessionById(session._id) }}>
                                        {
                                            editTitleForSessionId !== session._id &&
                                            <div className={`chat-title`} style={{ color: theme.sidePanelChatTitle }}>
                                                <span>{session.title}</span>
                                            </div>
                                        }
                                        {
                                            editTitleForSessionId === session._id &&
                                            <>
                                                <div className={`chat-title edit-mode`}>
                                                    <TextField
                                                        id="title-edit-text"
                                                        defaultValue={session.title}
                                                        variant="standard"
                                                        focused={true}
                                                        onChange={(event) => { setSessionRename(event.target.value) }}
                                                        onClick={(event) => { event.stopPropagation(); }}
                                                    />
                                                </div>
                                                <CloseIcon className="chat-actions cancel" onClick={(event: any) => { toggleTitleEdit(event, null) }} />
                                                <CheckIcon className="chat-actions ok" onClick={(event: any) => { updateSessionTitle(event, sessionRename, session._id) }} />
                                            </>

                                        }
                                        {
                                            sessionId === session._id && editTitleForSessionId !== session._id &&
                                            <EditIcon className="chat-actions edit control-position" onClick={(event: any) => { toggleTitleEdit(event, session._id) }} />
                                        }
                                        {
                                            editTitleForSessionId !== session._id &&
                                            <DeleteOutlineIcon className="chat-actions delete control-position" onClick={(event: any) => { deleteSession(event, session._id) }} />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </>
                }
            </div>
            <div className="panel-controls" style={{ justifySelf: 'flex-end' }}>
                <Button component="label" style={{ color: theme.sidePanelActionButtons }} onClick={() => { galleryLightbox.toggle(true) }} sx={{ color: 'black' }} variant="outlined" startIcon={<Logo fillColor={'none'} />}>
                    {t("Gallery")}
                </Button>
                <Button component="label" style={{ color: theme.sidePanelActionButtons }} onClick={() => { settingsLightbox.toggle(true) }} sx={{ color: 'black' }} variant="outlined" startIcon={<SettingsIcon />}>
                    {t("Settings")}
                </Button>
            </div>
        </div>
    );
}

export default ChatPanel;