import { useAuth0 } from "@auth0/auth0-react";
import "./login.css"
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Button } from "@mui/material";

export default function LoginPage() {

    const { loginWithRedirect } = useAuth0();
    const bgImages = [
        "images/welcome-screen/nlp-welcome-1.png",
        "images/welcome-screen/nlp-welcome-2.png",
        "images/welcome-screen/nlp-welcome-3.png",
        "images/welcome-screen/nlp-welcome-4.png",
        "images/welcome-screen/nlp-welcome-5.png",
        "images/welcome-screen/nlp-welcome-6.png",
        "images/welcome-screen/nlp-welcome-7.png",
        "images/welcome-screen/nlp-welcome-8.png",
        "images/welcome-screen/nlp-welcome-9.png",
        "images/welcome-screen/nlp-welcome-10.png",
    ];
    const handleClick = () => {
        loginWithRedirect();
    };

    const [backgroundImage, setBackgroundImage] = useState("");

    useEffect(() => {
        const randIndex = Math.floor(Math.random() * 10);
        setBackgroundImage(bgImages[randIndex]);
    }, []);
    return (
        <div className="login-container" style={
            {
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: .7
            }}>
            <div className="login-module">
                <p>Explore Imagery Analysis with ImiSight Chat</p>
                <div className="login-controls">
                    {/* <Button variant="contained" style={{width: '45%'}}>Log In</Button>
                    <Button variant="outlined" style={{width: '45%', float: 'right'}}>Sign Up</Button> */}
                    <Button className="login-btn" onClick={handleClick} variant="outlined" style={{ margin: '0 auto', display: 'block', color: '#0070c0', borderColor: '#0070c0', fontSize: '1vw' }}>Let's chat</Button>
                </div>
                <img className='splash-logo-imi' src='/images/light-mode/logo.png' alt='logo'></img>
            </div>
        </div>
    )
}