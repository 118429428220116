import { useSelector } from "react-redux";
import { IMessage, IMessageType, MessageSource, ISession } from "../models/message.model";
import { IProfile } from "../models/profile";
import { nlpStore, addChatSession, addMessage, addMessages, addChatSessions, setPendingReplay, setCurrentChatId, setProfile, toggleSessionLoading, deleteChatSession, setToken, updateChatSessionTitle } from "../store/npl.slice";
import axios from "./interceptor.service";
import EngineSettings from "../models/engine-settings.model";
import { BehaviorSubject } from "rxjs";
import { config } from "../config/config";

class NLPService {
    private static instance: NLPService | null = null;
    private baseUrl = `${config.nlpServiceEP}/imisight_NLP`;
    private index = 0;
    subscribeToTitleChange: BehaviorSubject<any> = new BehaviorSubject(null);

    private constructor() {
    }

    static getInstance(): NLPService {
        if (!NLPService.instance) {
            NLPService.instance = new NLPService();
        }
        return NLPService.instance;
    }

    setToken = (token: string) => {
        nlpStore.dispatch(setToken(token));
    };

    getLocale = () => {
        return axios.get<string>(this.baseUrl + "/getLocale")
            .then((res) => {
                return res.data;
                // nlpStore.dispatch(setProfile(res.data));
            }).catch(() => { });
    };

    setLocale = (locale: string) => {
        return axios.post<string>(this.baseUrl + "/setLocale", { locale })
            .then((res) => {
                // nlpStore.dispatch(setProfile(res.data));
            }).catch(() => { });
    };

    getProfile = () => {
        return axios.get<IProfile>(this.baseUrl + "/getProfile")
            .then((res) => {
                nlpStore.dispatch(setProfile(res.data));
            }).catch(() => { });
    };

    setProfile = (profile: IProfile) => {
        let formData = new FormData();
        formData.append('profile', JSON.stringify(profile));
        formData.append('profile-image', profile.profileImageSrc as Blob);
        return axios.post(this.baseUrl + "/setProfileImage", formData, { headers: { 'content-type': 'multipart/form-data' } })
            .then((res) => {
                nlpStore.dispatch(setProfile(res.data as IProfile));
            })
            .catch(() => { });
    };

    getEngineSettings = () => {
        return axios.get<any>(this.baseUrl + "/getEngineSettings").then(res => res.data);
    };

    setEngineSettings = (engineSettings: EngineSettings) => {
        return axios.post<any>(this.baseUrl + "/setEngineSettings", engineSettings).then(res => res.data);
    };

    getEngineAlgorithmTypes = () => {
        return axios.get<any>(this.baseUrl + "/getAlgorithmModel").then(res => { console.log(res); return res.data });
    };

    addMessage = (message: IMessage) => {
        nlpStore.dispatch(addMessage(message));
    };

    sendMessage = (message: IMessage) => {
        nlpStore.dispatch(setPendingReplay(true));

        let formData = new FormData();
        formData.append('message', JSON.stringify(message));
        formData.append('image', message.messageImage as Blob);
        return axios.post(this.baseUrl + "/sendMessage", formData, { headers: { 'content-type': 'multipart/form-data' } }).catch(() => { });
    };

    updateMessage = (message: IMessage) => {
        let formData = new FormData();
        formData.append('message', JSON.stringify(message));
        formData.append('image', message.messageImage as Blob);
        return axios.post(this.baseUrl + "/updateMessage", formData, { headers: { 'content-type': 'multipart/form-data' } }).catch(() => { });
    };

    getChatSessions = () => {
        return axios.get(this.baseUrl + "/getChatSessions").then((res: any) => {
            if (res.data.conversationList.length) {
                nlpStore.dispatch(addChatSessions(res.data.conversationList));
            } else {
                nlpStore.dispatch(addChatSessions([]));
            }
        }).catch(() => {
            console.error(`Failed to load sessions`);
            nlpStore.dispatch(addChatSessions([]));
        });
    };

    setCurrentChatId = (chatSessionId: string) => {
        nlpStore.dispatch(setCurrentChatId({ 'chatId': chatSessionId }));
    };

    toggleIsSessionLoading = (flag: boolean) => {
        nlpStore.dispatch(toggleSessionLoading(flag));
    };

    getMessagesBySessionId = (sessionId: string) => {
        return axios.get(this.baseUrl + `/getMessagesBySessionId/${sessionId}`).then((res: any) => {
            nlpStore.dispatch(addMessages(res.data.messagesList));
        }).catch(() => {
            console.error(`Failed to load session messages for session id: ${sessionId}`);
            nlpStore.dispatch(addMessages([]))
        });
    };

    getFavoriteGalery = (): Promise<any[]> => {
        return axios.get(this.baseUrl + `/getFavoriteGalery`).then((res: any) => {
            return res.data.favoriteImages;
        }).catch(() => {
            console.error(`Failed to load favorite gallery`);
        });
    };

    deleteSessionById = (sessionId: string) => {
        return axios.delete(this.baseUrl + `/deleteSessionById/${sessionId}`)
            .then((res: any) => {
                debugger;
                nlpStore.dispatch(deleteChatSession(res.data));
                // return this.getChatSessions();
            }).catch(() => {
                console.error(`Failed to delete session for session id: ${sessionId} `);
            });
    };

    updateSessionTitle = ((id: string, title: string): Promise<boolean> => {
        return axios.post(this.baseUrl + `/updateTitleConversation`, { id, title }).then((res: any) => {
            nlpStore.dispatch(updateChatSessionTitle({ id, title }));
            return true;
        }).catch(() => {
            console.error(`Failed to delete session for session id: ${id} `);
            return false;
        });
    });

    openNewChatSession = () => {
        return axios.get("/openNewChatSession").then((res: any) => {
            const date = new Date(Date.now());
            const chatSession: ISession = {
                _id: `${res.data.conversationID}`,
                lastImageSrc: "",
                pendingReplay: false,
                creationDate: date.toDateString(),
                last_update: date.toDateString(),
                title: date.toDateString()
            };
            nlpStore.dispatch(addChatSession(chatSession));

        }).catch(() => { });
    };

    downloadImage = (url: string) => {
        return axios.get("/downloadImage", { params: { url }, responseType: 'blob' });
    };

    addFavImage = (imageMessage: IMessage) => {
        return axios.post("/addNewFavoriteImage", { imageMessage });
    };

    deleteFavImage = (imageMessage: IMessage) => {
        return axios.post("/deleteFavoriteImage", { imageMessage });
    };

    sendEmail = (emailObj: IMessage, emailData: any) => {
        return axios.post("/sendEmail", { emailObj, emailData });
    };
}

export default NLPService.getInstance();