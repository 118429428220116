import axios from 'axios';
import { nlpStore } from '../store/npl.slice';
import { config } from "../config/config";

console.log(`Local VAR list : nlpService: ${config.nlpServiceEP} | socketIO: ${config.socketIOEP}`)

const instance = axios.create({
    baseURL: `${config.nlpServiceEP}/imisight_NLP`,
});

// Add an interceptor to the Axios instance
instance.interceptors.request.use(
    (config) => {
        // Get the token from your Redux slice state
        const token = nlpStore.getState().token;

        if (token) {
            // Add the token to the request headers
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;