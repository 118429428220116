export const lightTheme = {
  chatContainerPaddingLeft: '0',
  chatContainerMarginLeft: '7px',

  chatBubbleColorNLP: '#d3d1d1',
  chatTextColorNLP: ' #000',

  chatBubbleColorUser: '#8DEF96',
  chatTextColorUser: '#00223C',

  shareArrow: '#00223c',

  textboxColor: '#00223C',
  textboxBorder: '#E7E6E6',
  textboxIconColor: '#00223C',

  sidePanelBGColor: '#D3D1D1',
  sidePanelNewChatColor: '#00223C',
  sidePanelNewChatIconPath: 'images/light-mode/imisight-cirlcle.png',
  sidePanelTitle: '#00223C',
  sidePanelChatTitle: '#0070C0',
  sidePanelChatTitleHover: '#E7E6E6',
  sidePanelActionButtons: '#00223C',

  sidePanelBoxShadow: '6px 5px 5px rgb(128 128 128 / 60%)',
  logoPath: 'images/light-mode/logo.png',
  backgroundPath: 'images/light-mode/chat-background.svg',
  galleryIconPath: 'images/light-mode/gallery.svg',
  settingsIconPath: 'images/light-mode/settings.svg'
}

export const darkTheme = {
  chatContainerPaddingLeft: '7px',
  chatContainerMarginLeft: '0',

  chatBubbleColorNLP: '#00223c',
  chatTextColorNLP: '#fff',

  chatBubbleColorUser: '#7aad40',
  chatTextColorUser: '#fff',

  shareArrow: '#fff',

  textboxColor: '#E7E6E6',
  textboxBorder: '#E7E6E6',
  textboxIconColor: '#E7E6E6',

  sidePanelBGColor: '#00223C',
  sidePanelNewChatColor: '#E7E6E6',
  sidePanelNewChatIconPath: 'images/dark-mode/imisight-cirlcle.png',
  sidePanelTitle: '#C9C7C7',
  sidePanelChatTitle: '#E7E6E6',
  sidePanelChatTitleHover: '#002E50',
  sidePanelActionButtons: '#E7E6E6',

  sidePanelBoxShadow: 'none',//'6px 0px 4px rgb(0 0 0 / 83%)',
  logoPath: 'images/dark-mode/logo.png',
  backgroundPath: 'images/dark-mode/chat-background.svg',
  galleryIconPath: 'images/dark-mode/gallery.svg',
  settingsIconPath: 'images/dark-mode/settings.svg'
}
