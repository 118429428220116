import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { nlpStore } from "./store/npl.slice"
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { config } from './config/config'
import "./i18n"


const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <Provider store={nlpStore}>
    <Auth0Provider
      domain={config.auth0Domain || ""}
      clientId={config.auth0Client || ""}
      authorizationParams={{
        connection: "NLP",
        redirect_uri: window.location.origin
      }}>
      <App />
    </Auth0Provider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
