declare var configuration: any;

export const config = {
    auth0Domain:
        process.env.NODE_ENV === 'production' ? configuration.REACT_APP_AUTH0_DOMAIN : process.env.REACT_APP_AUTH0_DOMAIN,
    auth0Client:
        process.env.NODE_ENV === 'production' ? configuration.REACT_APP_AUTH0_CLIENT : process.env.REACT_APP_AUTH0_CLIENT,
    socketIOEP:
        process.env.NODE_ENV === 'production' ? configuration.REACT_APP_SOCKETIO_ENDPOINT : process.env.REACT_APP_SOCKETIO_ENDPOINT,
    nlpServiceEP:
        process.env.NODE_ENV === 'production' ? configuration.REACT_APP_NLP_SERVICE : process.env.REACT_APP_NLP_SERVICE
};