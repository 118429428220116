import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "A": "A",
            "Algorithm": "Algorithm",
            "B": "B",
            "Box Threshold": "Box Threshold",
            "E": "E",
            "Engine Settings": "Engine Settings",
            "G": "G",
            "Gallery": "Gallery",
            "General": "General",
            "General Settings": "General Settings",
            "H": "H",
            "High": "High",
            "L": "L",
            "Language": "Language",
            "Local": "Local",
            "Local Settings": "Local Settings",
            "Log Out": "Log Out",
            "Low": "Low",
            "M": "M",
            "Mid": "Mid",
            "N": "N",
            "Nickname": "Nickname",
            "P": "P",
            "Previous 30 Days": "Previous 30 Days",
            "Profile": "Profile",
            "Profile Image": "Profile Image",
            "Profile Settings": "Profile Settings",
            "S": "S",
            "Save": "Save",
            "Settings": "Settings",
            "Start new chat": "Start new chat",
            "T": "T",
            "Text Threshold": "Text Threshold",
            "Today": "Today",
            "U": "U",
            "Upload File": "Upload File",
            "W": "W",
            "Write your query here": "Write your query here",
            "Y": "Y",
            "Yesterday": "Yesterday",
        }
    },
    fr: {
        translation: {
            "A": "A",
            "Algorithm": "Algorithme",
            "B": "B",
            "Box Threshold": "Seuil de boîte",
            "E": "E",
            "Engine Settings": "Paramètres du moteur",
            "G": "G",
            "General": "Général",
            "General Settings": "réglages généraux",
            "H": "H",
            "High": "Haut",
            "L": "L",
            "Language": "Langue",
            "Local": "Locale",
            "Local Settings": "Paramètres locaux",
            "Log Out": "Se déconnecter",
            "Low": "Faible",
            "M": "M",
            "Mid": "Milieu",
            "N": "N",
            "Nickname": "Surnom",
            "P": "P.",
            "Previous 30 Days": "30 jours précédents",
            "Profile": "Profil",
            "Profile Image": "Image de profil",
            "Profile Settings": "Paramètres de profil",
            "S": "S",
            "Save": "Sauvegarder",
            "Start new chat": "Nouvelle discussion",
            "T": "T",
            "Text Threshold": "Seuil de texte",
            "Today": "Aujourd'hui",
            "U": "U",
            "Upload File": "Téléverser un fichier",
            "W": "W",
            "Write your query here": "Écrivez votre requête ici",
            "Y": "Y",
            "Yesterday": "Hier"
        }
    },
    de: {
        translation: {
            "A": "A",
            "Algorithm": "Algorithmus",
            "B": "B",
            "Box Threshold": "Boxschwelle",
            "E": "E",
            "Engine Settings": "Motoreinstellungen",
            "G": "G",
            "General": "Allgemein",
            "General Settings": "Allgemeine Einstellungen",
            "H": "H",
            "High": "Hoch",
            "L": "L",
            "Language": "Sprache",
            "Local": "Lokal",
            "Local Settings": "Lokale Einstellungen",
            "Log Out": "Ausloggen",
            "Low": "Niedrig",
            "M": "M",
            "Mid": "Mitte",
            "N": "N",
            "Start new chat": "Neuer Chat",
            "Nickname": "Spitzname",
            "P": "P",
            "Previous 30 Days": "Vorherige 30 Tage",
            "Profile": "Profil",
            "Profile Image": "Profilbild",
            "Profile Settings": "Profileinstellungen",
            "S": "S",
            "Save": "Speichern",
            "T": "T",
            "Text Threshold": "Textschwelle",
            "Today": "Heute",
            "U": "U",
            "Upload File": "Datei hochladen",
            "W": "W",
            "Write your query here": "Schreiben Sie hier Ihre Anfrage",
            "Y": "Y",
            "Yesterday": "Gestern"
        }
    },
    es: {
        translation: {
            "A": "A",
            "Algorithm": "Algoritmo",
            "B": "B",
            "Box Threshold": "Umbral de caja",
            "E": "E",
            "Engine Settings": "Configuración del motor",
            "G": "G",
            "General": "General",
            "General Settings": "Configuración general",
            "H": "H",
            "High": "Alto",
            "L": "L",
            "Language": "idioma",
            "Local": "Local",
            "Local Settings": "Configuraciones locales",
            "Log Out": "Cerrar sesión",
            "Low": "Bajo",
            "M": "M",
            "Mid": "Medio",
            "N": "norte",
            "Nickname": "Apodo",
            "P": "P",
            "Previous 30 Days": "30 días anteriores",
            "Profile": "Perfil",
            "Profile Image": "Imagen de perfil",
            "Profile Settings": "Configuración de perfil",
            "S": "S",
            "Save": "Ahorrar",
            "Start new chat": "Nueva conversación",
            "T": "T",
            "Text Threshold": "Umbral de texto",
            "Today": "Hoy",
            "U": "U",
            "Upload File": "Subir archivo",
            "W": "W",
            "Write your query here": "Escribe tu consulta aquí",
            "Y": "Y",
            "Yesterday": "Ayer"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;