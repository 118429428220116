import { useTheme } from "styled-components";
import "./header.css"

function NLPHeader() {
    const theme = useTheme();

    return (
        <div className={`headerContainer`}>
            <div className={`headerTextContainer`}>
            </div>
            <div className="logo" style={{ backgroundImage: `url(${theme.logoPath})` }}></div>
        </div>
    )
}

export default NLPHeader;