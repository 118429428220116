import { ChangeEvent, ReactNode, useContext, useEffect, useState } from 'react';
import appContext from '../../context/app.context';
import CloseIcon from '@mui/icons-material/Close';
import LogoutButton from '../login/logoutButton';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import './settings-panel.css';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import nlpService from '../../services/nlp.service';
import { IProfile } from '../../models/profile';
import { useSelector } from 'react-redux';
import { getProfile } from '../../store/npl.slice';
import { User, useAuth0 } from '@auth0/auth0-react';
import { Slider } from '@mui/material';
import EngineSettings from '../../models/engine-settings.model';
import ThemeToggle from '../themeToggler/themeToggler'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function SettingsPanel() {
    const { t, i18n } = useTranslation();
    const profile = useSelector(getProfile);
    const [profileImageSrc, setProfileImageSrc] = useState<string>("");
    const { settingsLightbox, appTheme }: any = useContext(appContext);
    const [selectedTab, setSelectedTab] = useState<string>("General");
    const [originalSettings, setOriginalSettings] = useState<any>(null);
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
    const [textThreshold, setTextThreshold] = useState<number>(0);
    const [boxThreshold, setBoxThreshold] = useState<number>(0);
    const [engineAlgorithm, setEngineAlgorithm] = useState<string>("");
    const [engineAlgorithmTypes, setEngineAlgorithmTypes] = useState<any>([]);
    const { user } = useAuth0();
    const { email, nickname, locale, picture } = user as User;
    const sliderMarks = [
        { value: 0.2, label: t("Low") },
        { value: 0.5, label: t("Mid") },
        { value: 0.9, label: t("High") }
    ];

    useEffect(() => {
        const promises = Promise.all([nlpService.getEngineSettings(), nlpService.getEngineAlgorithmTypes()]);//, nlpService.getProfile()
        promises.then((res) => {
            const tth = res[0].engine_settings.textThreshold;
            const bth = res[0].engine_settings.boxThreshold;
            const algo = res[0].engine_settings.algorithm;

            setTextThreshold(tth);
            setBoxThreshold(bth);
            setEngineAlgorithm(algo);

            setOriginalSettings({
                textThreshold: tth,
                boxThreshold: bth,
                algorithm: algo
            });

            setEngineAlgorithmTypes(res[1]);
        })
    }, []);

    useEffect(() => {
        setSaveEnabled();
    }, [engineAlgorithm, textThreshold, boxThreshold]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const imageSrc = e.target.files[0];
            setProfile(imageSrc);
        }
    };

    const setProfile = (profileImageSrc: File) => {
        const profile: IProfile = {
            ...user,
            profileImageSrc
        };
        nlpService.setProfile(profile);
    };

    const onSelectedTabChanged = (e: Event, tabStr: string) => {
        e.stopPropagation();
        setSelectedTab(tabStr);
    };

    const handleThemeChange = (event: SelectChangeEvent) => {
        let themeName = event.target.value;
        appTheme.setTheme(themeName.toLowerCase());
    };

    const handleDDChange = (event: SelectChangeEvent) => {
        let local = event.target.value;
        nlpService.setLocale(local).then((key: any) => {
            i18n.changeLanguage(local);
        });
    };

    const handleEngineAlgorithmChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
        setEngineAlgorithm(event.target.value);
    };

    const handleTextThresholdChange = (event: Event, newValue: number | number[]) => {
        setTextThreshold(newValue as number);
    };

    const handleBoxThresholdChange = (event: Event, newValue: number | number[]) => {
        setBoxThreshold(newValue as number);
    };

    const setSaveEnabled = () => {
        if (!originalSettings) { return; }

        const res = textThreshold === originalSettings.textThreshold &&
            boxThreshold === originalSettings.boxThreshold &&
            engineAlgorithm === originalSettings.algorithm;

        setIsSaveEnabled(!res);
    };

    const updateEngineSettings = (event: Event) => {
        event.stopPropagation();
        const engineSettings: EngineSettings = {
            algorithm: engineAlgorithm,
            boxThreshold,
            textThreshold
        }
        nlpService.setEngineSettings(engineSettings);
        setOriginalSettings(engineSettings);
        setIsSaveEnabled(false);
    };

    return (
        <div id="lightbox" onClick={() => { settingsLightbox.toggle(false) }}>
            <div className="settings-panel-container" onClick={(e: any) => { e.stopPropagation() }}>
                <div className="settings-options">
                    <div onClick={(event: any) => { onSelectedTabChanged(event, "General") }}>{t("General")}</div>
                    <div onClick={(event: any) => { onSelectedTabChanged(event, "Profile") }}>{t("Profile")}</div>
                    <div onClick={(event: any) => { onSelectedTabChanged(event, "Local") }}>{t("Local")}</div>
                    <div className={`settings-row`} style={{ marginTop: 'auto', lineHeight: 1 }}>
                        <div className={`settings-key`}></div>
                        <div className={`settings-value`}>
                            <LogoutButton></LogoutButton>
                        </div>
                    </div>
                </div>
                <div className="settings-content">
                    <div className='close-dialog'><CloseIcon onClick={() => { settingsLightbox.toggle(false) }} /></div>
                    <div hidden={selectedTab !== "General"}>
                        <div className="settings-title"><span>{t("Engine Settings")}</span></div>
                        <div className={`settings-table`}>
                            <div className={`settings-row`}>
                                <div className={`settings-key`}>{t("Algorithm")}</div>
                                <div className={`settings-value`} style={{
                                    display: "inline-flex",
                                    gap: "10px",
                                    width: '50%'
                                }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={engineAlgorithm || ""}
                                        sx={{ color: "black" }}
                                        onChange={handleEngineAlgorithmChange}
                                    >
                                        {engineAlgorithmTypes?.map((type: string, index: number) => {
                                            if (type.includes("*")) {
                                                return <MenuItem key={index} value={type} disabled>{type}</MenuItem>
                                            } else {
                                                return <MenuItem key={index} value={type}>{type}</MenuItem>
                                            }
                                        })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className={`settings-row`}>
                                <div className={`settings-key`}>{t("Box Threshold")}</div>
                                <div className={`settings-value`} style={{
                                    display: "inline-flex",
                                    gap: "10px",
                                    width: '50%'
                                }}>
                                    <Slider
                                        key={`slider-box`}
                                        aria-label="Small steps"
                                        value={boxThreshold}
                                        step={0.01}
                                        marks={sliderMarks}
                                        onChange={handleBoxThresholdChange}
                                        min={0.10}
                                        max={1.00}
                                    />
                                </div>
                            </div>
                            <div className={`settings-row`}>
                                <div className={`settings-key`}>{t("Text Threshold")}</div>
                                <div className={`settings-value`} style={{
                                    display: "inline-flex",
                                    gap: "10px",
                                    width: '50%'
                                }}>
                                    <Slider
                                        key={`slider-text`}
                                        aria-label="Small steps"
                                        value={textThreshold}
                                        step={0.01}
                                        marks={sliderMarks}
                                        onChange={handleTextThresholdChange}
                                        min={0.10}
                                        max={1.00}
                                    />
                                </div>
                            </div>
                            <div className={`settings-row`} style={{ justifyContent: 'flex-end' }}>
                                <Button variant="text" disabled={!isSaveEnabled} onClick={(event: any) => { updateEngineSettings(event) }}>{t("Save").toUpperCase()}</Button>
                            </div>
                        </div>
                    </div>
                    <div hidden={selectedTab !== "Profile"}>
                        <div className="settings-title"><span>{t("Profile Settings")}</span></div>
                        <div className={`settings-table`}>
                            <div className={`settings-row`}>
                                <div className={`settings-key`}>{t("Nickname")}</div>
                                <div className={`settings-value`} style={{
                                    display: "inline-flex",
                                    gap: "10px",
                                    width: '50%'
                                }}>
                                    {nickname}
                                </div>
                            </div>
                            <div className={`settings-row`}>
                                <div className={`settings-key`}>{t("Email")}</div>
                                <div className={`settings-value`} style={{
                                    display: "inline-flex",
                                    gap: "10px",
                                    width: '50%'
                                }}>
                                    {email}
                                </div>
                            </div>
                            {
                                locale && <div className={`settings-row`}>
                                    <div className={`settings-key`}>{t("Locale")}</div>
                                    <div className={`settings-value`} style={{
                                        display: "inline-flex",
                                        gap: "10px",
                                        width: '50%'
                                    }}>
                                        {locale}
                                    </div>
                                </div>
                            }
                            <div className={`settings-row`}>
                                <div className={`settings-key`}>{t("Profile Image")}</div>
                                <div className={`settings-value`} style={{
                                    display: "inline-flex",
                                    gap: "10px",
                                    width: '50%'
                                }}>
                                    <Avatar alt="UserProfile" src={profile.picture || picture || ""} />
                                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                        {t("Upload File")}
                                        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div hidden={selectedTab !== "Local"}>
                        <div className="settings-title"><span>{t("Local Settings")}</span></div>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="select-standard-label">{t("Language")}</InputLabel>
                            <Select
                                labelId="select-standard-label"
                                id="select-standard"
                                value={i18n.language}
                                onChange={handleDDChange}
                                label={t("Language")}
                            >
                                <MenuItem value="en" selected={i18n.language === "en"}>
                                    <em>EN</em>
                                </MenuItem>
                                <MenuItem value="fr" selected={i18n.language === "fr"}>
                                    <em>FR</em>
                                </MenuItem>
                                <MenuItem value="de" selected={i18n.language === "de"}>
                                    <em>DE</em>
                                </MenuItem>
                                <MenuItem value="es" selected={i18n.language === "es"}>
                                    <em>ES</em>
                                </MenuItem>
                            </Select>
                        </FormControl> <br />
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="select-standard-label">{t("Theme")}</InputLabel>
                            <Select
                                labelId="select-standard-label"
                                id="select-standard"
                                value={appTheme.theme}
                                onChange={handleThemeChange}
                                label={t("Theme")}>
                                <MenuItem value="light" selected={appTheme.theme === "light"}>
                                    <em>Light</em>
                                </MenuItem>
                                <MenuItem value="dark" selected={appTheme.theme === "dark"}>
                                    <em>Dark</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div >
    )
}